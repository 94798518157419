import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
// import { AddressAutofill } from '@mapbox/search-js-react';
import "./Map.css";
import geoJson from "./toronto-studios.json";


mapboxgl.accessToken =
  "pk.eyJ1IjoibXlkbWRpIiwiYSI6ImNraDFodzVzbjAyMDYyem16YjhjZHMzeTAifQ.RgHNiPsLdWUYEcWjaKBiKw";

const Map = () => {
  
  
  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-79.4032, 43.6432],
      zoom: 12,
      
    });

    // Initial Values upon loading the map
    map.on("load", function () {
      // Add an image to use as a custom marker
      map.loadImage(
        // "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        //'./assets/custom_market_freen.png',
        //  "./assets/maker-icons/mapbox-marker-icon-20px-red.png",
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', 
          
        function (error, image) {
          if (error) throw error;
          map.addImage("custom-marker", image);
          map.addImage("85-marker", image);
          // Add a GeoJSON source with multiple points
          map.addSource("points", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: geoJson.features,
            },
          });
          // Add a symbol layer
          map.addLayer({
            id: "points",
            type: "symbol",
            source: "points",
            layout: {
              "icon-image": "custom-marker",
              // get the title name from the source's "title" property
              "text-field": ["get", "title"],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 1.25],
              "text-anchor": "top",
            },
            
          });
          
         // Sample Exmaple Overlays - Abinyah Sep 8 2023
         
          // map.addLayer({
          //   id: 'terrain-data',
          //   type: 'line',
          //   source: {
          //     type: 'vector',
          //     url: 'mapbox://mapbox.mapbox-terrain-v2'
          //   },
          //   'source-layer': 'contour'
          // });

        // //  
        }
      );

      // Clickable Points = Sep 2023

      // When a click event occurs on a feature in the places layer, open a popup at the
          // location of the feature, with description HTML from its properties.
          map.on('click', 'points', (e) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;
            const title = e.features[0].properties.title;
            const alpha = e.features[0].properties.alpha;
            
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            
            new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(
              `<h3>${description}</h3><p>${title}</p><p>${alpha}</p>`
              )
            .addTo(map);
            });
            
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'places', () => {
            map.getCanvas().style.cursor = 'pointer';
            });
            
            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'places', () => {
            map.getCanvas().style.cursor = '';
            });
            
  
    });
    // Add a new point
    // Create a new marker.
    

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    
    // Adding new Nav conrtrols Abinyah 
    const nav = new mapboxgl.NavigationControl({
      showZoom: false,
      visualizePitch: false,
      showCompass: true
      });
      map.addControl(nav, 'bottom-right');
    // end Nav controls
    
    // Adding Full Screen Map Control to Bottom Right
    map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');

    // Adding User Location Tracking
    map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
          enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
     }), 'bottom-right');

//. Code Snippets 
// AddMiniMap




// End Code Snippets

    // Clean up on unmount
    return () => map.remove();
  }, []);

  return <div className="map-container" ref={mapContainerRef} />
  ;
  
  // Adding Form
  

};

export default Map;

//List mapGL version number in the browser console
// console.log(`Mapbox GL JS v${mapboxgl.version}`);
