import React from "react";
import Map from "./Map";


function App() {
  return (
 <Map />
  
);
  
}

export default App;
